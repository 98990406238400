<template>
  <div  id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
     
      <div class="row p-2" style="margin-top: 4%;">
        <div class="col-lg-12">
          <div class="widjet">
             <div class="widjethdr dispflex">
              <div style="width: auto">
             
                <h1>Academic Management</h1>
              </div>
              
                <div style="width: 14%;">
              <span class="has-float-label" style="margin-top: 4%;">
                   <select
										class="form-control form-input"
										id="deptlistid"
                    v-model="selectedClass"
                    @change="getSection(selectedClass)"
									
										>
										<option :value=null>-- Select --</option>
							  		<option v-for="(item,index) in classList" :key="index" :value="item._id">
											{{ item.className }}
										</option>
										</select>
                    <label for="clslistid">Select Class </label>
              </span>
              </div>
             <div style="width: 14%;">
               <span class="has-float-label" style="margin-top: 4%;">
                   <select
										class="form-control form-input"
										id="deptlistid"
                     v-model="selectedSection">
										<option :value=null>-- Select --</option>
									
                  	<option v-for="(item,index) in sectionList" :key="index" :value="item._id">
											{{ item.name }}
										</option>
										</select>
                    <label for="clslistid">Select Classroom </label>
              </span>
             </div>
              
              <!-- <div style="margin-top:10px" class="flexitm">
                 
                <input type="text" v-model="searchWords" placeholder="Search Students">
              </div> -->
              <div class="mt-1">
                
                <button type="button" class="backbtn">
          <i class="fa fa-arrow-left"></i>
        </button>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";


export default {
  name: "filter",
  data() {
   return {
     classList: [],
     sectionList: [],
     selectedClass: null,
     selectedSection: null,
   }
  },
  created() {
    this.getClassWithSection()
  },
  computed: {
      styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      }
  },
 

  methods:{
   getSection(classId){
     this.sectionList = this.classList.find( x => x._id == classId).sections
   },
   async getClassWithSection() {

      let userData = secureUI.sessionGet("user");

      let getClassCounts = this.$store.getters.getClassAndSectionList

       if(getClassCounts.length == 0){
          if (!userData) {
              this.$toasted.error("Please login and do the action");
              this.$router.push('/login');
          } else {
            
              const response = await ViService.viXGet(
                `/academic/class_with_sections`,
                userData.token
              );

            if (response.isSuccess) {

              const resultData = secureUI.secureGet(response.data);

              this.$store.dispatch('setClassAndSectionList',resultData.data);
              this.classList = resultData.data

              } else {

                this.$toasted.error(response.message);

              }       
          }
       }else{
         this.classList = this.$store.getters.getClassAndSectionList
       }
    }
  }
};
</script>

